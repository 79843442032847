.navbar {
    background-color: rgba($color: (#000000), $alpha: .5);
    position: fixed;
    width: 100%;
    color: white;
    text-decoration: none;
    padding: 1rem;
    z-index: 1000;
}

.pageTitle {
    color: white;
}


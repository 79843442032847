html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.Layout_navbar__k5-km {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  color: white;
  text-decoration: none;
  padding: 1rem;
  z-index: 1000; }

.Layout_pageTitle__2XIOE {
  color: white; }

#root {
  height: 100%; }

.Loading_content__rZDfw {
  display: -webkit-flex;
  display: flex;
  height: 100vh;
  width: 100vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

